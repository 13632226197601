import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
  Grid,
  Chip,
  Link,
  List,
  ListItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import { getReceiptURL, uploadReceipt } from "firebaseConfig";
import { useState } from "react";
import { updateTransaction } from "store/reducers/transaction";

const TransactionView = ({ data }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const [receipt, setReceipt] = useState(null);

  const handleUploadReceipt = () => {
    if (!receipt) {
      console.log("Receipt is null");
      return;
    }
    uploadReceipt(data.transaction_id, receipt);
    delete data.invoiceNumber;
    delete data.status;
    delete data.Actions;
    delete data.Receipt;
    updateTransaction({ ...data, has_recepit: true });
    setReceipt(null);
  };

  const handleOpenReceipt = () => {
    getReceiptURL(data.transaction_id)
      .then((url) => {
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TableRow sx={{ "&:hover": { bgcolor: `transparent !important` }, overflow: "hidden" }}>
      <TableCell colSpan={8} sx={{ p: 2.5, overflow: "hidden" }}>
        <Transitions type="slide" direction="down" in={true}>
          <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
            <Grid item xs={12} sm={5} md={4} lg={4} xl={3}>
              <MainCard>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <List component="nav" aria-label="main mailbox folders" sx={{ py: 0 }}>
                      <Link>
                        <ListItem>
                          <input
                            type="file"
                            accept=".pdf"
                            onChange={(e) => {
                              setReceipt(e.target.files[0]);
                            }}
                          />
                        </ListItem>
                      </Link>
                      <Link>
                        <ListItem>
                          <Chip
                            color="primary"
                            label="Upload receipt"
                            size="small"
                            variant="light"
                            sx={{ width: "100%" }}
                            onClick={() => handleUploadReceipt()}
                          />
                        </ListItem>
                      </Link>
                      <Link>
                        <ListItem>
                          <Chip
                            color="primary"
                            label="Open receipt"
                            size="small"
                            variant="light"
                            sx={{ width: "100%" }}
                            onClick={() => handleOpenReceipt()}
                          />
                        </ListItem>
                      </Link>
                    </List>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
            <Grid item xs={12} sm={7} md={8} lg={8} xl={9}>
              <Stack spacing={2.5}>
                <MainCard>
                  <List sx={{ py: 0 }}>
                    <ListItem>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={0.5}>
                            <Typography color="secondary">Transaction ID</Typography>
                            <Typography>{data.transaction_id}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={0.5}>
                            <Typography color="secondary">Reference</Typography>
                            <Typography>{data.reference}</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem divider={!matchDownMD}>
                      <Stack spacing={0.5}>
                        <Typography color="secondary">Time</Typography>
                        <Typography>{data.time}</Typography>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={0.5}>
                            <Typography color="secondary">Description</Typography>
                            <Typography>{data.description}</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </MainCard>
              </Stack>
            </Grid>
          </Grid>
        </Transitions>
      </TableCell>
    </TableRow>
  );
};

TransactionView.propTypes = {
  data: PropTypes.object,
};

export default TransactionView;
