import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Papa from "papaparse";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Chip, Dialog, Grid, Stack, Tooltip, Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import IconButton from "components/@extended/IconButton";
import { PopupTransition } from "components/@extended/Transitions";

import AddTransaction from "sections/transactions/AddTransaction";
import TransactionView from "sections/transactions/TransactionView";
import AlertTransactionComplete from "sections/transactions/AlertTransactionComplete";

import { useSelector } from "store";

// assets
import { CloseOutlined, EyeTwoTone, EditTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { ReactTable } from "components/tables/ReactTable";
import { createTransaction, updateTransaction } from "store/reducers/transaction";
import { uploadReceipt } from "firebaseConfig";

const CustomCell = ({ row }) => {
  const { values } = row;
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Stack spacing={0}>
        <Typography variant="subtitle1">{values.reference}</Typography>
        <Typography variant="caption" color="textSecondary">
          {values.transaction_id.slice(0, 10)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const StatusCell = ({ value }) => {
  return value ? (
    <Chip color="success" label="Accounted" size="small" variant="light" />
  ) : (
    <Chip color="error" label="No receipt" size="small" variant="light" />
  );
};

const ActionCell = (
  row,
  setTransaction,
  setCustomerCompleteId,
  add,
  handleAdd,
  handleComplete,
  theme
) => {
  const collapseIcon = row.isExpanded ? (
    <CloseOutlined style={{ color: theme.palette.error.main }} />
  ) : (
    <EyeTwoTone twoToneColor={theme.palette.secondary.main} />
  );
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
      <Tooltip title="View">
        <IconButton
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            row.toggleRowExpanded();
          }}
        >
          {collapseIcon}
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            if (!add) {
              const tempOrder = { ...row.values };
              delete tempOrder.Actions;
              setTransaction(tempOrder);
            } else {
              setTransaction(null);
            }
            handleAdd();
          }}
        >
          <EditTwoTone twoToneColor={theme.palette.primary.main} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

StatusCell.propTypes = {
  value: PropTypes.string,
};

CustomCell.propTypes = {
  row: PropTypes.object,
};

const Transactions = () => {
  const theme = useTheme();
  const { transactions } = useSelector((state) => state.transaction);
  const [csvFile, setCsvFile] = useState(null);
  const fileInputRef = useRef(null); // Using a ref to access the file input

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  const handleImport = () => {
    if (!csvFile) return;

    Papa.parse(csvFile, {
      complete: (result) => {
        // Assuming CSV columns have headers
        result.data.forEach((row) => {
          const transaction = {};

          Object.keys(row).forEach((key) => {
            // Convert field names to lowercase and replace spaces with underscores
            const formattedKey = key
              .toLowerCase() // Convert to lowercase
              .replace(/\s+/g, "_"); // Replace spaces with underscores

            // Add the formatted key-value pair to the transaction object
            transaction[formattedKey] = row[key];
          });

          createTransaction(transaction);
        });
      },
      header: true, // This tells PapaParse to treat the first row as header
    });
  };

  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [customerCompleteId, setCustomerCompleteId] = useState(null);
  const [receipt, setReceipt] = useState(null);

  const handleAdd = () => {
    setAdd(!add);
    if (transaction && !add) setTransaction(null);
  };

  const handleComplete = (transaction) => {
    if (!receipt) {
      console.log("No receipt file selected.");
      return;
    }
    console.log("handleComplete: Receipt: ", receipt);
    uploadReceipt(transaction.transaction_id, receipt);
    delete transaction.invoiceNumber;
    delete transaction.status;
    delete transaction.Actions;
    delete transaction.Receipt;
    updateTransaction({ ...transaction, has_recepit: true });
    setReceipt(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "invoiceNumber",
      },
      {
        Header: "#",
        accessor: "transaction_id",
        Cell: ({ value }) => <Typography variant="subtitle1">{value.slice(0, 10)}</Typography>,
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount",
        accessor: "amount",
        // render text as color success
        Cell: ({ row }) => (
          <Typography
            color={
              row.values.type === "DEPOSIT" ? theme.palette.success.main : theme.palette.error.main
            }
          >
            {row.values.amount + " €"}
          </Typography>
        ),
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Status",
        accessor: "has_recepit",
        Cell: StatusCell,
      },
      {
        Header: "Actions",
        className: "cell-center",
        disableSortBy: true,
        Cell: ({ row }) =>
          ActionCell(
            row,
            setTransaction,
            setCustomerCompleteId,
            add,
            handleAdd,
            handleComplete,
            theme
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const renderRowSubComponent = useCallback(({ row }) => <TransactionView data={row.values} />, []);
  return (
    <>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Typography variant="h3">Transactions</Typography>
      </Grid>
      <MainCard content={false}>
        <ScrollX>
          <ReactTable
            columns={columns}
            data={transactions}
            handleAdd={handleAdd}
            getHeaderProps={(column) => column.getSortByToggleProps()}
            renderRowSubComponent={renderRowSubComponent}
            hiddenColumns={["invoiceNumber"]}
          />
        </ScrollX>
        <AlertTransactionComplete title={customerCompleteId} open={open} />
        <Dialog
          maxWidth="sm"
          TransitionComponent={PopupTransition}
          keepMounted
          fullWidth
          onClose={handleAdd}
          open={add}
          sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
          aria-describedby="alert-dialog-slide-description"
        >
          <AddTransaction transaction={transaction} onCancel={handleAdd} />
        </Dialog>

        <div>
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }} // Hidden file input
            onChange={handleFileChange}
            ref={fileInputRef} // Attach the ref to the file input
          />
          <button onClick={() => fileInputRef.current.click()}>Choose CSV File</button>
          <button onClick={handleImport}>Import CSV</button>
        </div>
      </MainCard>
    </>
  );
};

export default Transactions;
