import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// material-ui
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// third-party
import _ from "lodash";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import AlertTransactionComplete from "./AlertTransactionComplete";
import Avatar from "components/@extended/Avatar";
import IconButton from "components/@extended/IconButton";

import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";
import { createOrder, deleteOrder, updateOrder } from "store/reducers/order";

const orderDefault = {
  customer: "",
  businessId: "",
  vatNumber: "",
  email: "",
  phone: "",
  status: "Active",
  street: "",
  postCode: "",
  city: "",
  country: "",
  price: 0,
  due: "7 vrk",
  info: "",
  order: "",
  paymentMethod: "Bank transfer",
  projectStarted: new Date().toLocaleDateString("en-GB").replaceAll("/", "."),
  statusUpdated: new Date().toLocaleDateString("en-GB").replaceAll("/", "."),
};

const allStatus = ["Active", "Billed"];

const AddOrder = ({ order, onCancel }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [orderObj, setOrderObj] = useState(orderDefault);

  useEffect(() => {
    if (order) {
      setOrderObj(_.merge({}, orderDefault, order));
    } else {
      setOrderObj(orderDefault);
    }
  }, [order]);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    onCancel();
  };

  const handleInput = (field, value) => {
    setOrderObj({
      ...orderObj,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    if (order) {
      updateOrder(orderObj);
      dispatch(
        openSnackbar({
          open: true,
          message: "Order update successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          close: false,
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: "Order add successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          close: false,
        })
      );
    }
    onCancel();
  };

  const handleDelete = () => {
    deleteOrder(order.id);
    dispatch(
      openSnackbar({
        open: true,
        message: "Order delete successfully.",
        variant: "alert",
        alert: {
          color: "success",
        },
        close: false,
      })
    );
    onCancel();
  };

  const isCreating = !order;

  const formik = useFormik({
    orderDefault,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate>
            <DialogTitle>{order ? "Edit Order" : "New Order"}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                    <Avatar alt="Avatar 1" sx={{ width: 72, height: 72, border: "1px" }} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-name">Customer</InputLabel>
                        <TextField
                          fullWidth
                          id="customer"
                          value={orderObj.customer}
                          onChange={(e) => handleInput("customer", e.target.value)}
                          placeholder="Enter Customer Name"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel>Business ID</InputLabel>
                        <TextField
                          fullWidth
                          id="businessId"
                          placeholder="Enter Business ID"
                          value={orderObj.businessId}
                          onChange={(e) => handleInput("businessId", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel>VAT No.</InputLabel>
                        <TextField
                          fullWidth
                          id="vatNumber"
                          placeholder="Enter VAT No."
                          value={orderObj.vatNumber}
                          onChange={(e) => handleInput("vatNumber", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-email">Email</InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          placeholder="Enter Customer Email"
                          value={orderObj.email}
                          onChange={(e) => handleInput("email", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-phone">Phone</InputLabel>
                        <TextField
                          fullWidth
                          id="phone"
                          placeholder="Enter Customer Phone"
                          value={orderObj.phone}
                          onChange={(e) => handleInput("phone", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel>Street</InputLabel>
                        <TextField
                          fullWidth
                          id="street"
                          placeholder="Enter Street"
                          value={orderObj.street}
                          onChange={(e) => handleInput("street", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel>Post Code</InputLabel>
                        <TextField
                          fullWidth
                          id="postCode"
                          placeholder="Enter Post Code"
                          value={orderObj.postCode}
                          onChange={(e) => handleInput("postCode", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel>City</InputLabel>
                        <TextField
                          fullWidth
                          id="city"
                          placeholder="Enter City"
                          value={orderObj.city}
                          onChange={(e) => handleInput("city", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel>Country</InputLabel>
                        <TextField
                          fullWidth
                          id="country"
                          placeholder="Enter City"
                          value={orderObj.country}
                          onChange={(e) => handleInput("country", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-status">Status</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="column-hiding"
                            displayEmpty
                            input={
                              <OutlinedInput id="select-column-hiding" placeholder="Sort by" />
                            }
                            value={orderObj.status === "" ? "Select status" : orderObj.status}
                            onChange={(e) => handleInput("status", e.target.value)}
                          >
                            {allStatus.map((column) => (
                              <MenuItem key={column} value={column}>
                                <ListItemText primary={column} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel>Info</InputLabel>
                        <TextField
                          fullWidth
                          id="info"
                          multiline
                          rows={2}
                          placeholder="Enter Additonal Information"
                          value={orderObj.info}
                          onChange={(e) => handleInput("info", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel>Custom order name</InputLabel>
                        <TextField
                          fullWidth
                          id="order"
                          placeholder="Enter Custom Order Name"
                          value={orderObj.order}
                          onChange={(e) => handleInput("order", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel>Payment method</InputLabel>
                        <TextField
                          fullWidth
                          id="paymentMethod"
                          placeholder="Enter Custom Order Name"
                          value={orderObj.paymentMethod}
                          onChange={(e) => handleInput("paymentMethod", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                      <Stack spacing={1.25}>
                        <InputLabel>Price</InputLabel>
                        <TextField
                          fullWidth
                          id="price"
                          type="number"
                          value={orderObj.price}
                          onChange={(e) => handleInput("price", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                      <Stack spacing={1.25}>
                        <InputLabel>Due Date</InputLabel>
                        <TextField
                          fullWidth
                          id="due"
                          placeholder="Enter Due Date"
                          value={orderObj.due}
                          onChange={(e) => handleInput("due", e.target.value)}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {!isCreating && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton onClick={handleDelete} size="large" color="error">
                        <DeleteFilled />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                      {order ? "Edit" : "Add"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {!isCreating && (
        <AlertTransactionComplete
          title={order.customer}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

AddOrder.propTypes = {
  order: PropTypes.any,
  onCancel: PropTypes.func,
};

export default AddOrder;
