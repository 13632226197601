import { createSlice } from "@reduxjs/toolkit";
import { collection, deleteDoc, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "firebaseConfig";
import { dispatch } from "store";

/**
 * Initial state for the transaction slice.
 */
const initialState = {
  transactions: [],
  transaction: null,
  error: null,
};

/**
 * Redux slice for managing transactions.
 * @type {Slice}
 */
const transaction = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload.error;
    },

    getAll(state, action) {
      state.transactions = action.payload;
    },
  },
});

export default transaction.reducer;

export const { getAll } = transaction.actions;

/**
 * Function to subscribe to transaction updates from Firestore.
 * @function
 * @returns {Function} Unsubscribe function.
 */
export const subscribeToTransactions = () => {
  const unsubscribe = onSnapshot(collection(firestore, "transactions"), (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(transaction.actions.getAll(data));
  });

  return unsubscribe;
};

/**
 * Function to create a new transaction in Firestore.
 * @async
 * @param {object} transaction - The transaction object to be created.
 */
export async function createTransaction(transaction) {
  try {
    const docref = doc(collection(firestore, "transactions"), transaction.transaction_id);
    await setDoc(docref, transaction);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to update an existing transaction in Firestore.
 * @async
 * @param {object} transaction - The updated transaction object.
 */
export async function updateTransaction(transaction) {
  try {
    const docRef = doc(collection(firestore, "transactions"), transaction.transaction_id);
    await updateDoc(docRef, transaction);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to delete an transaction from Firestore.
 * @async
 * @param {string} id - The ID of the transaction to be deleted.
 */
export async function deleteTransaction(id) {
  try {
    const docRef = doc(collection(firestore, "transactions"), id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error(error);
  }
}
