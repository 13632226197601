// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import snackbar from "./snackbar";
import transaction from "./transaction";
import order from "./order";
import sale from "./sale";
import contact from "./contact";
import data from "./data";
import user from "./user";

/**
 * Combines all reducers into a single reducer function.
 * @returns {Function} A reducer function that combines all individual reducers.
 */
const reducers = combineReducers({
  menu,
  snackbar,
  transaction,
  order,
  sale,
  contact,
  data,
  user,
});

export default reducers;
