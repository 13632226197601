import { lazy } from "react";

import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Transactions from "pages/transactions";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));
const Orders = Loadable(lazy(() => import("pages/orders")));
const Sales = Loadable(lazy(() => import("pages/sales")));
const Contacts = Loadable(lazy(() => import("pages/contacts")));
const ContactList = Loadable(lazy(() => import("pages/contacts/ContactList")));
const Profile = Loadable(lazy(() => import("pages/profile")));

/**
 * Main routes configuration.
 * @type {object}
 */
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
    {
      path: "transactions",
      element: <Transactions />,
    },
    {
      path: "orders",
      element: <Orders />,
    },
    {
      path: "sales",
      element: <Sales />,
    },
    {
      path: "contacts",
      element: <Contacts />,
    },
    {
      path: "contacts/:country",
      element: <ContactList />,
    },
    {
      path: "profile",
      element: <Profile />,
    },
  ],
};

export default MainRoutes;
