import { createSlice } from "@reduxjs/toolkit";
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "firebaseConfig";
import { dispatch } from "store";

/**
 * Initial state for the contact slice.
 */
const initialState = {
  contacts: [],
  contact: null,
  count: null,
  error: null,
};

/**
 * Redux slice for managing contacts.
 * @type {Slice}
 */
const contact = createSlice({
  name: "contact",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload.error;
    },

    getAll(state, action) {
      state.contacts = action.payload;
    },

    get(state, action) {
      state.contact = action.payload;
    },

    getCount(state, action) {
      state.count = action.payload;
    },

    create(state, action) {
      state.contacts = [...state.contacts, action.payload];
    },

    update(state, action) {
      const { newContact } = action.payload;
      const contactUpdate = state.contacts.map((item) => {
        if (item.id === newContact.id) {
          return newContact;
        }
        return item;
      });
      state.contacts = contactUpdate;
    },

    delete(state, action) {
      const { contactId } = action.payload;
      const deleteContact = state.contacts.filter((contact) => contact.id !== contactId);
      state.contacts = deleteContact;
    },
  },
});

export default contact.reducer;

export const { getAll } = contact.actions;

/**
 * Subscribes to changes in the contacts collection for a specific country with optional query filters.
 *
 * @param {string} country The country whose contacts you want to subscribe to.
 * @param {number} lmt The limit to the number of contacts returned.
 * @param {Array} filters Array of filter objects containing field, operation, and value for query conditions.
 * @returns {Function} A function that unsubscribes from the Firestore listener when invoked.
 */
export const subscribeToContacts = (country, lmt, filters = []) => {
  // Build the base query
  let firestoreQuery = query(collection(firestore, `contacts/${country}/contacts`), limit(lmt));

  // Apply optional filters if provided
  filters.forEach(({ field, operation, value }) => {
    if (field && operation && value !== undefined) {
      firestoreQuery = query(firestoreQuery, where(field, operation, value));
    }
  });

  // Set up the subscription and handle incoming data
  const unsubscribe = onSnapshot(firestoreQuery, (snapshot) => {
    const contacts = [];
    snapshot.docs.forEach((doc) => {
      const docData = doc.data();
      Object.keys(docData).forEach((key) => {
        contacts.push(docData[key]);
      });
    });
    dispatch(contact.actions.getAll(contacts));
  });

  return unsubscribe;
};

/**
 * Get all contacts for a specific country.
 * @param {string} country
 * @returns {Promise<Array>} An array of contacts.
 */
export async function getAllContacts(country) {
  const colRef = collection(firestore, "contacts/" + country + "/contacts");
  const snapshot = await getDocs(colRef);
  return snapshot.docs.map((doc) => doc.data());
}

/**
 * Gets the count of contacts in Estonia.
 * @param {string} country - The country to get the count for.
 * @returns {Promise<number>} The count of contacts.
 */
export async function getCount(country) {
  const docRef = doc(firestore, "contacts/" + country);
  const snapshot = await getDoc(docRef);
  return snapshot.data().contacts_count;
}

/**
 * Gets the last fetched date for a specific country.
 * @param {string} country – The country to get the last fetched date for.
 * @returns {Promise<timestamp>} The last fetched date.
 */
export async function getLastFetched(country) {
  const docRef = doc(firestore, "contacts/" + country);
  const snapshot = await getDoc(docRef);
  return snapshot.data().last_fetched.toDate();
}

/**
 * Deletes a contact from contact's contacts collection.
 * @param {string} country - The country to delete the contact from.
 * @param {string} id - The ID of the contact to delete.
 */
export async function deleteContact(country, id) {
  console.log("Delete: " + id);
  return;
  try {
    const docRef = doc(firestore, `contacts/${country}/contacts/${id}`);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error deleting contact:", error);
  }
}
