import PropTypes from "prop-types";
import React, { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// third-party
import { CSVLink } from "react-csv";

// assets
import { CaretUpOutlined, CaretDownOutlined, DownloadOutlined } from "@ant-design/icons";

/**
 * Header component for sorting columns.
 * @param {object} props - Props object.
 * @param {object} props.column - Column object.
 * @param {boolean} props.sort - Sort status.
 */
export const HeaderSort = ({ column, sort }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box sx={{ width: "max-content" }}>{column.render("Header")}</Box>
      {!column.disableSortBy && (
        <Stack
          sx={{ color: "secondary.light" }}
          {...(sort && { ...column.getHeaderProps(column.getSortByToggleProps()) })}
        >
          <CaretUpOutlined
            style={{
              fontSize: "0.625rem",
              color:
                column.isSorted && !column.isSortedDesc ? theme.palette.text.secondary : "inherit",
            }}
          />
          <CaretDownOutlined
            style={{
              fontSize: "0.625rem",
              marginTop: -2,
              color: column.isSortedDesc ? theme.palette.text.secondary : "inherit",
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

HeaderSort.propTypes = {
  column: PropTypes.any,
  sort: PropTypes.bool,
};

/**
 * Table pagination component.
 * @param {object} props - Props object.
 * @param {function} props.gotoPage - Function to navigate to a specific page.
 * @param {array} props.rows - Array of table rows.
 * @param {function} props.setPageSize - Function to set page size.
 * @param {number} props.pageSize - Page size.
 * @param {number} props.pageIndex - Page index.
 */
export const TablePagination = ({ gotoPage, rows, setPageSize, pageSize, pageIndex }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePagination = (event, value) => {
    gotoPage(value - 1);
  };

  const handleChange = (event) => {
    setPageSize(+event.target.value);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ width: "auto" }}>
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" color="secondary">
              Row per page
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={pageSize}
                onChange={handleChange}
                size="small"
                sx={{ "& .MuiSelect-select": { py: 0.75, px: 1.25 } }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="caption" color="secondary">
            Go to
          </Typography>
          <TextField
            size="small"
            type="number"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 0;
              gotoPage(page - 1);
            }}
            sx={{ "& .MuiOutlinedInput-input": { py: 0.75, px: 1.25, width: 36 } }}
          />
        </Stack>
      </Grid>
      <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
        <Pagination
          count={Math.ceil(rows.length / pageSize)}
          page={pageIndex + 1}
          onChange={handleChangePagination}
          color="primary"
          variant="combined"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

TablePagination.propTypes = {
  gotoPage: PropTypes.func,
  setPageSize: PropTypes.func,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  rows: PropTypes.array,
};

/**
 * Component to display the number of selected rows.
 * @param {object} props - Props object.
 * @param {number} props.selected - Number of selected rows.
 */
export const TableRowSelection = ({ selected }) => (
  <>
    {selected > 0 && (
      <Chip
        size="small"
        label={`${selected} row(s) selected`}
        color="secondary"
        variant="light"
        sx={{
          position: "absolute",
          right: -1,
          top: -1,
          borderRadius: "0 4px 0 4px",
        }}
      />
    )}
  </>
);

TableRowSelection.propTypes = {
  selected: PropTypes.number,
};

/**
 * Component for selecting column for sorting.
 * @param {object} props - Props object.
 * @param {string} props.sortBy - Column ID to sort by.
 * @param {function} props.setSortBy - Function to set column ID to sort by.
 * @param {array} props.allColumns - Array of all columns.
 */
export const SortingSelect = ({ sortBy, setSortBy, allColumns }) => {
  const [sort, setSort] = useState(sortBy);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSort(value);
    setSortBy([{ id: value, desc: false }]);
  };

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        id="column-hiding"
        displayEmpty
        value={sort}
        onChange={handleChange}
        input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
        renderValue={(selected) => {
          const selectedColumn = allColumns.filter((column) => column.id === selected)[0];
          if (!selected) {
            return <Typography variant="subtitle1">Sort By</Typography>;
          }

          return (
            <Typography variant="subtitle2">
              Sort by (
              {typeof selectedColumn.Header === "string"
                ? selectedColumn.Header
                : selectedColumn?.title}
              )
            </Typography>
          );
        }}
        size="small"
      >
        {allColumns
          .filter((column) => column.canSort)
          .map((column) => (
            <MenuItem key={column.id} value={column.id}>
              <ListItemText
                primary={typeof column.Header === "string" ? column.Header : column?.title}
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

SortingSelect.propTypes = {
  setSortBy: PropTypes.func,
  sortBy: PropTypes.string,
  allColumns: PropTypes.array,
};

/**
 * Converts a Firestore timestamp object to a formatted date string.
 * @param {object} timestamp - Firestore timestamp object with seconds and nanoseconds.
 * @returns {string} - Formatted date string.
 */
function formatFirestoreTimestamp(timestamp) {
  if (!timestamp || typeof timestamp !== "object" || !timestamp.seconds) {
    console.error("Invalid or missing timestamp object:", timestamp);
    return "";
  }

  // Create a new Date object using the seconds from the timestamp
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  return date.toLocaleDateString("en-GB");
}

/**
 * CSV download component.
 * @param {object} props - Props object.
 * @param {array} props.data - Data to download.
 * @param {string} props.filename - Filename.
 * @param {array} props.columns - Array of all columns.
 */
export const CSVExport = ({ data, filename, headers }) => {
  const formattedData = data.map((item) => ({
    ...item,
    registered: formatFirestoreTimestamp(item.registered),
  }));

  return (
    <CSVLink data={formattedData} filename={filename} headers={headers}>
      <Tooltip title="CSV Export">
        <DownloadOutlined
          style={{ fontSize: "24px", color: "gray", marginTop: 4, marginRight: 4, marginLeft: 4 }}
        />
      </Tooltip>
    </CSVLink>
  );
};

CSVExport.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.any,
  filename: PropTypes.string,
};
