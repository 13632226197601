import PropTypes from "prop-types";

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";

// project import
import Avatar from "components/@extended/Avatar";
import { PopupTransition } from "components/@extended/Transitions";

// assets
import { CheckCircleFilled } from "@ant-design/icons";

export default function AlertOrderComplete({ title, open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color="success" sx={{ width: 72, height: 72, fontSize: "1.75rem" }}>
            <CheckCircleFilled />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              {"Are you sure you want to complete this order?"}
            </Typography>
            <Typography align="center">
              Make sure the order has been completed and you have received the payment from the
              customer. Also check that the invoice has been uploaded for the order.
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button
              fullWidth
              onClick={() => handleClose(false)}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="success"
              variant="contained"
              onClick={() => handleClose(true)}
              autoFocus
            >
              Complete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

AlertOrderComplete.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
